import { createContext, useReducer } from "react";
import {
  CLEAR_STATE,
  SET_CURRENT_ID,
  SET_ETA,
  SET_ETA_TIME,
  SET_FAKE_UPDATED,
  SET_PRICE_CHANGE_INTERVAL,
  SET_PRICE_CHANGE_VALUES,
  SET_TIMER,
  SET_TIMER_STATUS,
} from "./actions";
import PriceChangeReducer, {
  initialState,
  TimerStatus,
} from "./PriceChangeReducer";

const PriceChangeContext = createContext();

export const PriceChangeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PriceChangeReducer, initialState);

  const setup = (
    addBy,
    totalVariant,
    countDownTime,
    id,
    alreadyUpdated,
    ETA
  ) => {
    setCurrentFakeUpdated(alreadyUpdated);
    setCurrentID(id);
    console.log("Starting the Countdown", new Date());
    const interval = setInterval(() => {
      const diff = countDownTime - new Date().getTime();
      if (diff <= 0) {
        setETATime(0);
        console.log("Stopping the Countdown", new Date());
        setTimerStatus(TimerStatus.DONE_NOT_FINISHED);
      } else {
        setETATime(diff);
      }
    }, 1000);
    let timeForOneVariantUpdate = ETA / (totalVariant - alreadyUpdated);
    let variantsUpdatedPerSec = Math.ceil(1 / timeForOneVariantUpdate);
    console.log(
      "Time for One Variant To Update, ",
      timeForOneVariantUpdate,
      countDownTime - new Date().getTime(),
      countDownTime,
      totalVariant,
      alreadyUpdated,
      "Variants Updated Per Sec",
      variantsUpdatedPerSec
    );
    console.log("Starting the Interval for variants", new Date());
    let intervalPerVariant = setInterval(() => {
      dispatch({
        type: SET_PRICE_CHANGE_VALUES,
        payload: {
          totalVariant,
          addBy: variantsUpdatedPerSec,
          alreadyUpdated,
        },
      });
      // console.log(
      //   "Updated Variant Count ",
      //   new Date().getTime(),
      //   new Date(),
      //   addBy,
      //   totalVariant,
      //   "timeForOneVariantUpdate",
      //   timeForOneVariantUpdate
      // );
    }, 1000);

    setPriceChangeInterval([interval, intervalPerVariant]);
  };

  const clearState = () => {
    dispatch({
      type: CLEAR_STATE,
    });
  };

  const setETATime = (time) => {
    dispatch({
      type: SET_ETA_TIME,
      payload: time,
    });
  };

  const setTimerRunning = (isTrue) => {
    dispatch({
      type: SET_TIMER,
      payload: isTrue,
    });
  };

  const setCurrentFakeUpdated = (updated) => {
    dispatch({
      type: SET_FAKE_UPDATED,
      payload: updated,
    });
  };

  const setPriceChangeInterval = (interval) => {
    dispatch({
      type: SET_PRICE_CHANGE_INTERVAL,
      payload: interval,
    });
  };

  const setTimerStatus = (status) => {
    dispatch({
      type: SET_TIMER_STATUS,
      payload: status,
    });
  };

  const setCurrentID = (id) => {
    dispatch({
      type: SET_CURRENT_ID,
      payload: id,
    });
  };

  const clearPriceChangeInterval = (interval) => {
    interval.forEach((interval_) => {
      clearInterval(interval_);
    });

    setPriceChangeInterval([]);
    clearState();
  };

  return (
    <PriceChangeContext.Provider
      value={{
        ...state,
        setup,
        setETATime,
        setTimerRunning,
        setCurrentFakeUpdated,
        setPriceChangeInterval,
        clearPriceChangeInterval,
        setCurrentID,
      }}
    >
      {children}
    </PriceChangeContext.Provider>
  );
};

export default PriceChangeContext;
