export const SET_ETA = "SET_ETA";
export const SET_TIMER = "SET_TIMER";
export const SET_FAKE_UPDATED = "SET_FAKE_UPDATED";
export const SET_ETA_TIME = "SET_ETA_TIME";
export const SET_SALES_INTERVAL = "SET_SALES_INTERVAL";
export const CLEAR_STATE = "CLEAR_STATE";
export const SET_TIMER_STATUS = "SET_TIMER_STATUS";
export const SET_SALES_VALUES = "SET_SALES_VALUES";
export const PREVIOUS_PRODUCT_STATUS = "PREVIOUS_PRODUCT_STATUS";
export const SET_CURRENT_ID = "SET_CURRENT_ID";
