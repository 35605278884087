import { createApp } from "@shopify/app-bridge";
import { authenticatedFetch as shopifyFetch } from "@shopify/app-bridge-utils";

export const authenticatedFetch = async ({path, method, body, signal,host, shop1, jwtToken}) => {
  const searchParams = new URLSearchParams(window.location.search);
  // const host = searchParams.get("host");

  
  
  // console.log("sdsvdv", shop1, host);

  if (NOT_EMBEDDED_STORES.indexOf(shop1) > -1) {
    // console.log("HVJVJV")
    const jwtToken2 = jwtToken;
    const expected_url = APP_ENV === 'development' ? BACKEND_HOST + path : path;
    
    return fetch(expected_url, {
      method,
      signal,
      body,
      headers: {
        'Authorization': `Bearer ${jwtToken2}`,
        "Content-Type": "application/json",
      },
    });
  } else {

    const app = createApp({
      apiKey: API_KEY,
      host: host,
    });
    return shopifyFetch(app)(path, {
      method,
      signal,
      body,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

};
