import { useContext, useReducer } from "react";
// import Cookies from "js-cookie";
import "isomorphic-fetch";
import { useRouter } from "next/router";
import _ from "lodash";
import DiscountContext from "./DiscountContext";
import discountReducer from "./discountReducer";
import { authenticatedFetch } from "../../utils/authenticatedFetch.js";
import AppContext from "../appContext";
// import moment from "moment-timezone";
let shop2;
let shopOrigin2;
let shopOrigin3;
let shopOrigin4;
let origin = "";
const changeQueryStringToJSON = (search) => {
  let pairs = search.slice(1).split("&");
  var result = {};
  pairs.forEach(function (pair) {
    pair = pair.split("=");
    result[pair[0]] = decodeURIComponent(pair[1] || "");
  });
  return result;
};
if (typeof window !== "undefined") {
  let search = window.location.search;
  let searchObject = changeQueryStringToJSON(search);
  origin = window.location.origin;
  // console.log(searchObject);
  // console.log(
  //   "window.location.search = ",
  //   search,
  //   window.location.href,
  //   window.location,
  //   changeQueryStringToJSON(search)
  // );
  if (search != "") {
    shop2 = search.split("&");
    // shopOrigin2 = shop2[5];
    // shopOrigin3 = shopOrigin2.split("=");
    // shopOrigin4 = shopOrigin3[1];
    shopOrigin4 = searchObject?.shop;
  } else {
    shopOrigin4 = window?.location.host;
    // alert("500 Internal Server Error");
  }
}

let shop = shopOrigin4;

let _id;

// const [salesLogStatusArr, setSalesLogStatusArr] = useState([]);
let salesLogStatusArr = [];
let storeMeteaFieldIDVal = 0;
let salesToastStatus = "";
let productSyncStatus = "";

const headers = {
  "Content-Type": "application/json",
};

const DiscountState = (props) => {
  const router = useRouter();

  const initialState = {
    discountRules: [],
    singleDiscountRule: null,
    loading: true,
    saving: false,
    loadingDiscounts: false,
    message: "This is from context",
    toggleStatus: "",
    topbarAlertStatus: "  ",

    bulkRules: [],
    loadingBulk: true,
    titlePrice: [],
    impactedVariants: {variants:[],impactedVariants:[]},
    BulkDiscountId: [],
    discountRulesPriceChange: [],
    ListBulkStartDate: [],
    ListBulkEndDate: [],
    countryCode: "",
    countryDate: "",
    countryTime: "",
    countryDateTime: null,
    countryCurrency: "",
    totalUpdatedProducts: "",
    totalUpdatedProductsReverting: "",
    variantCountArr: 0,
    analyticsCount: {},
    totalStoreProductsCount: 0,
    productsFetchingStatus: "",
    productsFetchingStatusCurrentCount: 0,
    productsFetchingStatusTotalCount: 0,
    priceLogFilterStatus: "",
    gettingFilterStatusFromRes: "",
    fileUploadData: [],
    mulipleBulkPriceChangeStatus: "",
    toastStatus: "",

    discountRulesCreateSale: [],
    ListCreateSaleStartDate: [],
    ListCreateSaleEndDate: [],

    salesClockData: [],
    saleLogToEdit: {},
    variantsToReRunSaleOn: [],
    variantsToReRunSaleOnWithProducts: [],
    queueStatus: {},
    uploadedSKU: [],
    storeDataTimeCurrency: {},
    filterLazyLoading: false,
    logUnderObs: null,
    subscriptionStatus: false,
    totalAppliedRules: 0,
    hamburgerState: false,
    error: {
      type: false,
      message: "",
    },
  };

  const {host, jwtToken, shop1}=useContext(AppContext);

  const [state, dispatch] = useReducer(discountReducer, initialState);
  const setSubscribtionStatus = (value) => {
    dispatch({ type: "SET_SUBSCRIPTION_STATUS", payload: value });
  };
  const setHamburgetState = (value) => {
    // console.log("Value , ", value);
    dispatch({ type: "SET_HAMBURGER_STATE", payload: value });
  };
  const setTotalAppliedRules = (value) => {
    dispatch({ type: "SET_TOTAL_APPLIED_RULES", payload: value });
  };
  const getSubscriptionStatus = (callback = () => {}) => {
    console.log("Subscription");
    authenticatedFetch({path: `/api/getSubscriptionStatus/${shop}?shop=${shop1}`, method: "GET", host, jwtToken,shop1})
      .then((res) => {
        console.log("Res",res);
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getSubscriptionStatus");
        }
      })
      .then((data) => {
        const { subscription, totalAppliedRules, error, message } = data;
        if (error == true) {
          callback(error, data);
        } else {
          setSubscribtionStatus(subscription);
          setTotalAppliedRules(totalAppliedRules);
          callback(false, data);
        }
      })
      .catch((err) => {
        callback(true);
      });
  };
  const switchToPro = async (callback) => {
    authenticatedFetch({path: `/api/createSubscription/${shop}?shop=${shop1}`, method: "GET", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed To Buy Pro Plan");
      })
      .then((data) => {
        const { subscription, totalAppliedRules, error, message } = data;
        if (error == true) {
          callback(error, data);
        } else {
          console.log("switchToPro", subscription, data);
          setSubscribtionStatus(subscription);
          // setTotalAppliedRules(totalAppliedRules);
          callback(false, data);
        }
      })
      .catch((err) => {
        console.log(err);
        callback(true);
      });
  };
  const switchToFree = async (callback) => {
    authenticatedFetch({path: `/api/switchToFree/${shop}?shop=${shop1}`, method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed To Buy Pro Plan1");
      })
      .then((data) => {
        const { subscription, totalAppliedRules, error, message } = data;
        if (error == true) {
          callback(error, data);
        } else {
          console.log("switchToFree", subscription, data);
          setSubscribtionStatus(subscription);
          // setTotalAppliedRules(totalAppliedRules);
          callback(false, data);
        }
      })
      .catch((err) => {
        callback(true);
      });
  };
  const handleAPIError = (message, type = "error") => {
    // variant could be success, error, warning, info, or default
    dispatch({ type: "SET_API_ERROR", payload: { type, message } });
  };
  const setVariantsToReRunSaleOn = (
    variants,
    notImpactedVariantsWithProducts,
    isBulkChange,
    saleLogUnderObs
  ) => {
    dispatch({ type: `SET_VARIANTS_TO_RERUN_SALE_ON`, payload: variants });
    dispatch({
      type: `SET_VARIANTS_TO_RERUN_SALE_ON_WITH_PRODUCTS`,
      payload: notImpactedVariantsWithProducts,
    });
    dispatch({ type: "LOG_UNDER_OBSERVATION", payload: saleLogUnderObs });
    router.push(isBulkChange ? "/RerunBulkChange" : "/RerunSale");
  };
  const setSaleLogToEdit = (saleLogObj) => {
    dispatch({ type: `SET_SALE_LOG_TO_EDIT`, payload: saleLogObj });
    router.push("/EditSalesLog");
  };
  const checkQueueStatus = () => {
    authenticatedFetch({path: `/api/checking_queue_status/${shop}?shop=${shop1}`, method: "GET", host, jwtToken,shop1})
      .then((res) => {
        console.log("Queye status", res);
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong checking_queue_status");
        }
      })
      .then((data) => {
        dispatch({ type: "SET_QUEUE_STATUS", payload: data });
      })
      .catch((error) => {});
  };
  //set loading for singleDiscount rule true
  const setLodingTrue = () => {
    dispatch({ type: `SET_LOADING_TRUE` });
  };

  //set loading for singleDiscount rule false
  const setLodingFalse = () => {
    dispatch({ type: `SET_LOADING_FALSE` });
  };

  //set loading for discountRules true
  const setLodingDiscountsTrue = () => {
    dispatch({ type: `SET_LOADING_DISCOUNTS_TRUE` });
  };

  //set loading for bulkRules true
  const setLodingBulkTrue = () => {
    dispatch({ type: `SET_LOADING_BULK_TRUE` });
  };

  //set loading for discountRules false
  const setLodingDiscountsFalse = () => {
    dispatch({ type: `SET_LOADING_DISCOUNTS_FALSE` });
  };

  //set loading for BulkRules false
  const setLodingBulkFalse = () => {
    dispatch({ type: `SET_LOADING_BULK_FALSE` });
  };

  //set saving true
  const setSavingTrue = () => {
    dispatch({ type: `SET_SAVING_TRUE` });
  };

  //set saving false
  const setSavingFalse = () => {
    dispatch({ type: `SET_SAVING_FALSE` });
  };

  // Create a Storewide Sale Rule
  const createStorewideSale = async (rulesObj) => {
    rulesObj.store = shop;
    rulesObj.createdBy = _id;

    authenticatedFetch({path: `/api/storewideSale?shop=${shop1}`,method: "POST", body: JSON.stringify(rulesObj), host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong storewideSale");
        }
      })
      .then((res) => {
        dispatch({ type: `CREATE_STOREWIDE_SALE`, payload: rulesObj });
      })
      .catch((error) => {});
  };
  const reRunSale = async (id) => {
    authenticatedFetch({path: `/api/storewideSale_rerun_sale?shop=${shop1}`, method: "POST", body: JSON.stringify({ data: { id: id, store: shop } }), host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong storewideSale_rerun_sale");
        }
      })
      .then((res) => {})
      .catch((error) => {});
  };
  const checkSaleLogEndDates = async (callback) => {
    //api/checkSaleLogEndDates/:shop
    authenticatedFetch({path: `/api/checkSaleLogEndDates/${shop}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong checkSaleLogEndDates");
        }
      })
      .then(({ saleWithEndDateExists }) => {
        callback(saleWithEndDateExists);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // List Discount Rules
  const listDiscountRules = () => {
    setLodingDiscountsTrue();

    //send store/shop as query
    authenticatedFetch({path: `/api/discount?store=${shop}`, method: "GET",host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong discount");
        }
      })
      .then(({ discounts }) => {
        dispatch({ type: `LIST_DISCOUNT_RULES`, payload: discounts });
      })
      .then(() => {
        setLodingDiscountsFalse();
      })
      .catch((error) => {});
  };

  // Get a single Discount Rule
  /*
    discount_id is the id of the discount obj from discount collection
    type_id is the id of the type of discount it has (storewide sale id, buld order id, etc)
    type is the number indicating the type of the discount
  */
  const getSingleDiscountRule = (discount_id, type_id, type) => {
    setLodingTrue();
    //check which type of discount it is
    /*
      0 = Storewide Sale
      1 = Bulk Order
      etc...
    */
    //if type is storewide Sale
    if (type === "0") {
      authenticatedFetch({path: `/api/storewideSale/${type_id}?shop=${shop1}`, method: "get", host, jwtToken,shop1})
        .then((res) => {
          if (res.ok) return res.json();
          else {
            throw new Error("Something went wrong storewideSale");
          }
        })
        .then(({ storewideSale, discount }) => {
          dispatch({
            type: `GET_SINGLE_DISCOUNT_RULE`,
            payload: { storewideSale, discount },
          });
        })
        .then(() => {
          setLodingFalse();
        })
        .catch((error) => {});
    }
  };

  //delete a Discount Rule
  const deleteDiscountRule = (discount_id, type_id, type) => {
    //check which type of discount it is
    /*
      0 = Storewide Sale
      1 = Bulk Order
      etc...
    */
    //if type is storewide Sale
    if (type === 0) {
      authenticatedFetch({path: `/api/storewideSale/${discount_id}?shop=${shop1}`, method: "delete", host, jwtToken,shop1})
        .then((res) => {
          if (res.ok) return res.json();
          else {
            throw new Error("Something went wrong i/storewideS");
          }
        })
        .then(({ msg, storewideSale, discount }) => {
          dispatch({ type: `DELETE_DISCOUNT`, payload: discount_id });
        })
        .then(() => {
          listDiscountRules();
        })
        .catch((error) => {});
    }
  };

  //clear single discount rule
  const clearSingleDiscountRule = () => {
    dispatch({
      type: `CLEAR_SINGLE_DISCOUNT`,
    });
  };

  //update a Discount Rule
  const updateDiscountRule = (type_id, type, discountObj) => {
    setSavingTrue();
    //check which type of discount it is
    /*
      0 = Storewide Sale
      1 = Bulk Order
      etc...
    */
    //if type is storewide Sale
    if (type === 0) {
      authenticatedFetch({path: `/api/storewideSale/${type_id}?shop=${shop1}`,method:"PUT", body:JSON.stringify(discountObj), host,jwtToken,shop1})
        .then((res) => {
          if (res.ok) return res.json();
          else {
            throw new Error("Something went wrong api/storewideSale/");
          }
        })
        .then(({ msg, storewideSale, discount }) => {})
        .then(() => {
          setSavingFalse();
        })
        .then(() => {
          //redirect on successfull update
          // router.push("/AddedDiscounts");
        })
        .catch((error) => {
          //set saving false on error to avoid saving loop
          setSavingFalse();
          //redirect on error
          // router.push("/AddedDiscounts");
        });
    }
  };

  //toggle isActive of a discount
  const toggleIsActive = (_id, index) => {
    dispatch({ type: `TOGGLE_IS_ACTIVE`, payload: index });

    authenticatedFetch({path:`/api/discount/toggleIsActive/${_id}?shop=${shop1}`,method: "put",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong discount/toggleIsActive");
        }
      })
      .then((res) => {
        dispatch({ type: `TOGGLE_STATUS`, payload: res.msg });
      })
      .catch((error) => {});
  };

  // To test context api
  const changeMessage = (newMessage) => {
    dispatch({ type: `CHANGE_MESSAGE`, payload: newMessage });
  };

  /////////////////// BULK PRICE CHANGE/////////////////

  const clearProductFilteringList = () => {
    dispatch({ type: "BulkDiscount", payload: [] });
  };

  const createBulkOperation = async (data, recordsPerPage = 100) => {
    data.forEach((filter) => {
      if (filter.search_attribute == "productTag")
        filter.search_key = filter.search_key.toString().toLowerCase();
    });
    const resetValues = () => {
      dispatch({ type: "BulkDiscount", payload: [] });
      dispatch({ type: "BulkDiscountId", payload: [] });
      dispatch({ type: "VARIANT_COUNT", payload: 0 });

      dispatch({ type: "PRICE_LOG_FILTER_STATUS", payload: "success" });
      dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });
    };
    const handleResponse = async (res, currentRequestNumber) => {
      if (res.status == "success") {
        const finArr = [];
        let variantCount = 0;
        for (let i = 0; i < res.results.length; i++) {
          for (let j = 0; j < res.results[i].variants.length; j++) {
            const variantId_3 = res.results[i].variants[
              j
            ].variant_id.toString();
            const variantId_2 = variantId_3.split("/");
            const variantId = variantId_2[4];

            const price = res.results[i].variants[j].variant_price.toString();

            let comparePrice = res.results[i].variants[j].variant_com_price;

            if (!comparePrice) {
              comparePrice = "null";
            }
            finArr.push(variantId.concat("$", price.concat("$", comparePrice)));
            variantCount++;
          }
        }

        currentRequestNumber === 1 &&
          checkBulkPriceChangeRequestImpectedVariants(finArr);

        return {
          BulkDiscount: res.results,
          BulkDiscountId: finArr,
          totalVariantCount: res.totalVariantCount,
        };
        //   console.log(state.titlePrice, res.results);
        //   dispatch({ type: "BulkDiscount", payload: [...res.results]});
        //   dispatch({ type: "BulkDiscountId", payload: [...finArr ]});
        //   dispatch({ type: "VARIANT_COUNT", payload: res.totalVariantCount });
      } else {
        return {
          BulkDiscount: [],
          BulkDiscountId: [],
          totalVariantCount: res.totalVariantCount,
          notFound: true,
        };
      }
    };

    let currentPage = 0;
    let totalRequestToBeMade;
    let continue_ = true;
    var BulkDiscount_ = [];
    var BulkDiscountId_ = [];
    resetValues();
    // SET_FILTER_LAZY_LOADING
    dispatch({ type: "SET_FILTER_LAZY_LOADING", payload: true });
    while (continue_) {
      try {
        let response = await authenticatedFetch({path:`/api/createBulkOperation?shop=${shop1}`, method:"POST" ,body:JSON.stringify({ data, shop, currentPage, recordsPerPage }),host,jwtToken,shop1})
        response = await response.json();
        if (currentPage == 0) {
          totalRequestToBeMade = Math.ceil(
            response.totalRecords / recordsPerPage
          );
          // console.log(
          //   response.totalRecords,
          //   recordsPerPage,
          //   totalRequestToBeMade,
          //   response,
          //   currentPage
          // );
        }

        currentPage += 1;

        const data_ = await handleResponse(response, currentPage);
        if (!data_.notFound) {
          BulkDiscount_ = [...BulkDiscount_, ...data_.BulkDiscount];
          BulkDiscountId_ = [...BulkDiscountId_, ...data_.BulkDiscountId];
          dispatch({ type: "BulkDiscount", payload: [...BulkDiscount_] });
          dispatch({ type: "BulkDiscountId", payload: [...BulkDiscountId_] });
          dispatch({ type: "VARIANT_COUNT", payload: data_.totalVariantCount });
          dispatch({
            type: "PRICE_LOG_FILTER_STATUS",
            payload: response.status,
          });
          dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });
          // console.log("response.totalRecords = ", response?.totalRecords, "state.titlePrice.length", state.titlePrice?.length, "response.results.length", response.results?.length,"totalRequestToBeMade",totalRequestToBeMade );
          if (
            currentPage == totalRequestToBeMade ||
            totalRequestToBeMade === 0
          ) {
            dispatch({ type: "SET_FILTER_LAZY_LOADING", payload: false });
            break;
          }
        } else {
          dispatch({ type: "BulkDiscount", payload: [] });
          dispatch({ type: "BulkDiscountId", payload: [] });
          dispatch({ type: "VARIANT_COUNT", payload: 0 });
          dispatch({ type: "PRICE_LOG_FILTER_STATUS", payload: "success" });
          dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });
          dispatch({ type: "SET_FILTER_LAZY_LOADING", payload: false });
          break;
        }
      } catch (err) {
        // console.warn(err);
        continue_ = false;
        resetValues();
      }
    }
  };

  const checkBulkPriceChangeRequestImpectedVariants = (data) => {
    authenticatedFetch({path:`/api/checkBulkPriceChangeRequestImpectedVariants?shop=${shop1}`, method:"POST", body:JSON.stringify({ data, shop }),host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong checkBulkPriceChangeRequestImpectedVariants");
        }
      })
      .then((res) => {
        dispatch({
          type: `MULIPLE_BULK_PRICE_CHANGE_STATUS`,
          payload: res.status,
        });
      })
      .catch((error) => {});
  };
  const setVariantCount = (value) => {
    dispatch({ type: "VARIANT_COUNT", payload: value });
  };

  const saveBulkPriceChangeRequest = async (data, callback) => {
    data.store = shop;
    data.createdBy = _id;
    let payload = {
      urls: {
        mainRoute: `/api/saveBulkPriceChangeRequest?shop=${shop}`,
        bufferRoute: `/api/bufferData?shop=${shop}`,
      },
      keysToBuffer: ["productId", "excludedArray"],
      // lengthPerRequest:1,
      data,
      type: "BULK",
      method: "POST",
      shop,
    };
    if (data.showManualProductsContainer && !data.file_status) {
      if (data.excludedArray.length < data.includedVariantsData.length) {
        delete data.productId;
        delete data.includedVariantsData;
        data.includedStatus = false;
        if (data.excludedArray.length > payload.lengthPerRequest) {
          data.bufferStatus = true;
        }
      } else {
        data.productId = JSON.parse(JSON.stringify(data.includedVariantsData));
        data.includedStatus = true;
        delete data.excludedArray;
        delete data.includedVariantsData;
        if (data.productId.length > payload.lengthPerRequest) {
          data.bufferStatus = true;
        }
      }
    }
    console.log("Starting the Buffer Process with the info -> ", payload);
    await bufferRequest(payload, callback);
    // await fetch("/api/saveBulkPriceChangeRequest", {
    //   method: "post",
    //   headers,
    //   body: JSON.stringify({ data, shop }),
    // })
    //   .then((res) => {
    //   if (res.ok) return res.json();
    //   else {
    //     throw new Error("Something went wrong");
    //   }
    // })
    //   .then((res) => {
    //     dispatch({ type: `CREATE_PRICE_CHANGE`, payload: data });
    //   })
    //   .catch((error) => {});
  };

  const editBulkPriceChangeRequest = async (data) => {
    data.store = shop;
    data.createdBy = _id;
    authenticatedFetch({path:`/api/updateBulkPriceChangeLog?shop=${shop1}`,method: "POST", body:JSON.stringify({ data, shop }),host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong updateBulkPriceChangeLog");
        }
      })
      .then((res) => {
        dispatch({ type: `CREATE_PRICE_CHANGE`, payload: data });
      })
      .catch((error) => {});
  };

  const getBulkPriceChangeLogFirstTime = () => {
    setLodingBulkTrue();
    authenticatedFetch({path:`/api/getBulkPriceChangeLogs/${shop}?shop=${shop1}`,method: "get", host,jwtToken,shop1} )
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getBulkPriceChangeLogs");
        }
      })
      .then((data) => {
        console.log("Data:", data);
        // setTimeout(function () {
        handleBulkPriceChangeLogResult(data);
        setLodingBulkFalse();
        // }, 1000);
      })
      .catch((error) => {});
  };
  const handleBulkPriceChangeLogResult = ({
    bulk_price_responce,
    bulk_price,
    start_date,
    end_date,
  }) => {
    // console.log("RESPONSE #### -> ", bulk_price_responce);
    if (bulk_price_responce == 0) {
      dispatch({
        type: `LIST_BULK_RULES`,
        payload: [],
      });
      return;
    }

    // calculate required time
    // const timeDiff = new Date().getTime() - prevTime;
    // const impacted = bulk_price_responce.filter((item) => item._id === id)[0];
    // let newUpdated = impacted?.priceAppliedVariants.length - prevUpdated;
    // let perVariantTimeRequired = Math.floor(newUpdated / (timeDiff * 1000));
    // setVariantTimeRequired(perVariantTimeRequired);

    // const gettingFilterStatusFromResponse = bulk_price_responce.map(
    //   (item) => item.value[0]
    // );

    dispatch({
      type: `LIST_BULK_RULES`,
      payload: bulk_price_responce,
    });
    /*     const shouldShowSalesInProgressMessage = bulk_price_responce
    .reverse()
    .some(
      (item) => item.status == "reverting" || item.status == "inprogress"
    );
  if (shouldShowSalesInProgressMessage) setRevertingAlert("reverting");
  else setRevertingAlert(null);*/
    dispatch({ type: `LIST_BULK_START_DATE`, payload: start_date });
    dispatch({ type: `LIST_BULK_END_DATE`, payload: end_date });

    // dispatch({
    //   type: `GETTING_FILTER_STATUS_FROM_RES`,
    //   payload: gettingFilterStatusFromResponse,
    // });
  };
  const getBulkPriceChangeLog = () => {
    authenticatedFetch({path:`/api/getBulkPriceChangeLogs/${shop}?shop=${shop1}`,method: "get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getBulkPriceChangeLogs");
        }
      })
      .then(handleBulkPriceChangeLogResult)
      .catch((error) => {});
  };

  const cancelBulkPriceChangeLogs = async (metaFieldId) => {
    setLodingBulkTrue();

    authenticatedFetch({path:`/api/cancelBulkPriceChangeLogs/${metaFieldId}?shop=${shop1}`,method:"get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong cancelBulkPriceChangeLogs");
        }
      })
      .then((res) => {
        getBulkPriceChangeLog();
        setTimeout(() => {
          setLodingBulkFalse();
        }, 1000);
      })
      .catch((error) => {});
  };

  const getUpdatedProducts = (caller = "initial") => {
    authenticatedFetch({path:`/api/getUpdatedProductsCount/${shop}?shop=${shop1}`,method: "get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getUpdatedProductsCount");
        }
      })
      .then(({ storeMeteaFieldID }) => {
        if (caller == "initial") {
          dispatch({ type: `UPDATED_PRODCUTS`, payload: storeMeteaFieldID });
        } else if (
          caller == "iteration" &&
          storeMeteaFieldID != storeMeteaFieldIDVal
        ) {
          dispatch({ type: `UPDATED_PRODCUTS`, payload: storeMeteaFieldID });
          storeMeteaFieldIDVal = storeMeteaFieldID;
          setTimeout(() => {
            getSaleLogs("force");
          }, 5000);
        } else {
        }
      })
      .catch((error) => {});
  };

  const productImpactedModalList = (id) => {
    // fetch(`/api/getUpdatedProductsCount`, {
    //   method: "get",
    // })
    //   .then((res) => {
    //    if (res.ok) return res.json();
    //   else {
    //     throw new Error("Something went wrong");
    //    }
    //  })
    //   .then(({ storeMeteaFieldID }) => {
    //     // console.log("Response -> Price Change Log -> GetUpdatedProducts -> ", storeMeteaFieldID);
    //     // dispatch({ type: `UPDATED_PRODCUTS`, payload: storeMeteaFieldID });
    //   })
    //   .catch((error) => {
    //     console.log(
    //       "Error -> Price Change Log -> Product Impacted Modal -> ",
    //       error
    //     );
    //   });
  };

  /////////////////// CREATE A SALE /////////////////
  const createStorewideBulkOperation = async (data) => {
    authenticatedFetch({path:`/api/createStorewideBulkOperation?shop=${shop1}`,method: "POST",body: JSON.stringify({ data, shop }),host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong createStorewideBulkOperation");
        }
      })
      .then((res) => {
        if (res.status == "success") {
          const finArr = [];
          let variantCount = 0;
          for (let i = 0; i < res.allproducts.length; i++) {
            for (let j = 0; j < res.allproducts[i].variants.length; j++) {
              const variantId_3 = res.allproducts[i].variants[
                j
              ].variant_id.toString();
              const variantId_2 = variantId_3.split("/");
              const variantId = variantId_2[4];

              const price = res.allproducts[i].variants[
                j
              ].variant_price.toString();

              let comparePrice =
                res.allproducts[i].variants[j].variant_com_price;

              if (!comparePrice) {
                comparePrice = "null";
              }
              finArr.push(
                variantId.concat("$", price.concat("$", comparePrice))
              );
              variantCount++;
            }
          }

          dispatch({ type: "BulkDiscount", payload: res.allproducts });
          dispatch({ type: "BulkDiscountId", payload: finArr });
          dispatch({ type: "VARIANT_COUNT", payload: variantCount });

          dispatch({ type: "PRICE_LOG_FILTER_STATUS", payload: res.status });
          dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });
        } else {
          dispatch({ type: "BulkDiscount", payload: [] });
          dispatch({ type: "BulkDiscountId", payload: [] });
          dispatch({ type: "VARIANT_COUNT", payload: 0 });

          dispatch({ type: "PRICE_LOG_FILTER_STATUS", payload: "success" });
          dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });
        }
      })
      .catch((error) => {});
  };

  const getStorewideFilteredProducts = async () => {};

  const bufferRequest = async (
    {
      urls,
      method,
      data,
      keysToBuffer,
      lengthPerRequest = 2000,
      type = "SALE",
      shop,
    },
    callback = () => {}
  ) => {
    const lengthOfBufferKeys = {};
    let maxLength = -1;

    //GET LENGTH OF EACH KEY

    keysToBuffer.forEach((key) => {
      if (data[key] != undefined) {
        let length = data[key].length;
        if (maxLength == -1) {
          maxLength = length;
        }
        lengthOfBufferKeys[key] = length;
      }
    });
    console.log("Buffer Keys -> ", keysToBuffer, data, lengthOfBufferKeys);

    //DIVIDE ALL THE BUFFER KEYS INTO CHUNKS
    let numberOfRequests = Math.ceil(maxLength / lengthPerRequest);
    if (numberOfRequests < 1) {
      authenticatedFetch({path:urls.mainRoute, method,body: JSON.stringify({
        data: { ...data, type, status: false, shop },
        shop,
      }),host,jwtToken,shop1})
        .then((response) => response.json())
        .then((response) => {
          callback();
          //  firstRequestResponse = response;
        })
        .catch(() => {
          callback();
        });
      return;
    }

    const size = lengthPerRequest;
    const bufferedData = {};
    Object.keys(lengthOfBufferKeys).forEach((key) => {
      console.log("-> ", key, data, data[key]);
      for (let i = 0; i < data[key].length; i += size) {
        if (!bufferedData[key]) bufferedData[key] = [];
        bufferedData[key].push(data[key].slice(i, i + size));
      }
    });
    console.log("Buffered Data ->", bufferedData);

    //MAKE HTTP REQUESTS

    let firstRequestResponse = null;
    for (let i = 0; i < numberOfRequests; i++) {
      let copyOfData = JSON.parse(JSON.stringify(data));
      try {
        Object.keys(bufferedData).forEach((key) => {
          if (bufferedData[key][i]) {
            copyOfData[key] = bufferedData[key][i];
          } else {
            copyOfData[key] = [];
          }
        });
        let response = {};
        if (i == 0) {
          let bufferStatus = true;
          if (i == numberOfRequests - 1) bufferStatus = false;
          console.log("Sending First Buffer Request", urls.mainRoute, {
            method: method,
            data: { ...copyOfData, type, status: bufferStatus, shop },
          });

          authenticatedFetch({path:urls.mainRoute,method, body:JSON.stringify({
            data: { ...copyOfData, type, status: bufferStatus, shop },
            shop,
          }),host,jwtToken,shop1})
            .then((response) => response.json())
            .then((response) => {
              firstRequestResponse = response;
              callback();
            })
            .catch(() => {
              callback();
            });

          console.log("First Request Response", firstRequestResponse);
        } else {
          if (i != numberOfRequests - 1) {
            console.log("Sending Buffer Request", urls.bufferRoute, {
              method: method,
              data: {
                ...copyOfData,
                ...firstRequestResponse,
                type,
                status: true,
                shop,
              },
            });

            response = await authenticatedFetch({path:urls.bufferRoute, method,body:JSON.stringify({
              data: {
                ...copyOfData,
                ...firstRequestResponse,
                type,
                status: true,
                shop,
              },
              shop,
            }),host,jwtToken,shop1})
          } else {
            console.log("Sending Last Buffer Request", urls.bufferRoute, {
              method: method,
              data: {
                ...copyOfData,
                ...firstRequestResponse,
                type,
                status: false,
                shop,
              },
            });
            response = await authenticatedFetch({path:urls.bufferRoute, method, body:JSON.stringify({
              data: {
                ...copyOfData,
                ...firstRequestResponse,
                type,
                status: false,
                shop,
              },
              shop,
            }),host,jwtToken,shop1})
          }
          //  response = await fetch(urls.bufferRoute, {method:method,data:{...copyOfData, ...firstRequestRespnse,type}});
        }
        // if(i==numberOfRequests-1){
        //   dispatch({ type: `CREATE_SALE_LOG`, payload: response });
        // }
      } catch (err) {
        console.error("buffer function -> ", err);
      }
    }
  };

  const saveCreateSaleRequest = async (data, callback) => {
    // console.log(data);
    data.store = shop;
    data.createdBy = _id;
    let payload = {
      urls: {
        mainRoute: `/api/saveCreateSaleRequest?shop=${shop}`,
        bufferRoute: `/api/bufferData?shop=${shop}`,
      },
      keysToBuffer: ["productId"],
      // lengthPerRequest:1,
      data,
      type: "SALE",
      method: "POST",
      shop,
    };
    // excludedVariants:excludedProducts,
    // includedVariants:includedProducts,
    console.log("Create Sale Data", JSON.parse(JSON.stringify(data)));
    if (data.productsAddedManually && !data.file_status) {
      if (data.excludedVariants.length < data.includedVariants.length) {
        data.productId = JSON.parse(JSON.stringify(data.excludedVariants));
        delete data.includedVariants;
        delete data.excludedVariants;
        data.includedStatus = false;
        // if (data.productId.length > payload.lengthPerRequest) {
        //   data.bufferStatus = true;
        // } else {
        //   data.bufferStatus = false;
        // }
      } else {
        data.productId = JSON.parse(JSON.stringify(data.includedVariants));
        data.includedStatus = true;
        delete data.excludedVariants;
        delete data.includedVariants;
        // if (data.productId.length > payload.lengthPerRequest) {
        //   data.bufferStatus = true;
        // } else {
        //   data.bufferStatus = false;
        // }
      }
    }
    // else {
    //   data.bufferStatus = true;
    //   data.includedStatus = false;
    // }
    console.log("Starting the Buffer Process with the info -> ", payload);
    await bufferRequest(payload, callback);
    // await fetch("/api/saveCreateSaleRequest", {
    //   method: "post",
    //   headers,
    //   body: JSON.stringify({ data, shop }),
    // })
    //   .then((res) => {
    //     if (res.ok) return res.json();
    //    else {
    //      throw new Error("Something went wrong");
    //   }
    //  })
    //   .then((res) => {
    //     // dispatch({ type: `CREATE_SALE_LOG`, payload: data });
    //   })
    //   .catch((error) => {});
  };
  const saveBulkSaleRequest = (data) => {
    data.store = shop;
    data.createdBy = _id;
    authenticatedFetch({path: `/api/saveBulkPriceChangeRequest?shop=${shop}`, method: "POST", body:JSON.stringify({ data, shop }),host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong saveBulkPriceChangeRequest");
        }
      })
      .then((res) => {
        dispatch({ type: `CREATE_SALE_LOG`, payload: data });
      })
      .catch((error) => {});
  };
  const editSaleRequest = async (data, callback) => {
    data.store = shop;
    data.createdBy = _id;
    let payload = {
      urls: {
        mainRoute: `/api/updateStoreSaleLog?shop=${shop}`,
        bufferRoute: `/api/bufferData?shop=${shop}`,
      },
      keysToBuffer: ["productId"],
      // lengthPerRequest:1,
      data,
      type: "SALE",
      method: "POST",
      shop,
    };
    // excludedVariants:excludedProducts,
    // includedVariants:includedProducts,
    console.log("Edit Sale Data", JSON.parse(JSON.stringify(data)));
    if (data.productsAddedManually && !data.file_status) {
      if (data.excludedVariants.length < data.includedVariants.length) {
        data.productId = JSON.parse(JSON.stringify(data.excludedVariants));
        delete data.includedVariants;
        delete data.excludedVariants;
        data.includedStatus = false;
        // if (data.productId.length > payload.lengthPerRequest) {
        //   data.bufferStatus = true;
        // } else {
        //   data.bufferStatus = false;
        // }
      } else {
        data.productId = JSON.parse(JSON.stringify(data.includedVariants));
        data.includedStatus = true;
        delete data.excludedVariants;
        delete data.includedVariants;
        // if (data.productId.length > payload.lengthPerRequest) {
        //   data.bufferStatus = true;
        // } else {
        //   data.bufferStatus = false;
        // }
      }
    }
    // else {
    // data.bufferStatus = true;
    // data.includedStatus = false;
    // }
    console.log("Starting the Buffer Process with the info -> ", payload);
    await bufferRequest(payload, callback);
    // await fetch("/api/updateStoreSaleLog", {
    //   method: "post",
    //   headers,
    //   body: JSON.stringify({ data, shop }),
    // })
    //   .then((res) => {
    //    if (res.ok) return res.json();
    //    else {
    //      throw new Error("Something went wrong");
    //    }
    //  })
    //   .then((res) => {
    //     dispatch({ type: `CREATE_SALE_LOG`, payload: data });
    //   })
    //   .catch((error) => {});
  };
  const getSpecificSaleLog = async (id, callback = (err, data) => {}) => {
    authenticatedFetch({path:`/api/getSpecificSaleLog/${id}?shop=${shop1}`, method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getSpecificSaleLog");
        }
      })
      .then((res) => {
        callback(false, res);
      })
      .catch((error) => {
        callback(true);
      });
  };
  const getSaleLogsFirstTime = () => {
    setLodingBulkTrue();
    authenticatedFetch({path: `/api/getSaleLogs/${shop}?shop=${shop1}`, method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getSaleLogs");
        }
      })
      .then((res) => {
        console.log("Sales Log First Time", res);
        // setTimeout(function () {
        setSalesLogStates(res);
        setLodingBulkFalse();
        // }, 1000);
      })
      .catch((error) => {});
  };

  function setSalesLogStates(res) {
    if (res.bulk_price_responce == 0) {
      dispatch({
        type: `LIST_CREATE_SALE_RULES`,
        payload: [],
      });
      return;
    }
    // let gettingFilterStatusFromResponse = [];
    // gettingFilterStatusFromResponse = res?.bulk_price_responce.map(
    //   (item) => item.value[0]
    // );
    // const shouldShowSalesInProgressMessage = res?.bulk_price_responce.some(
    //   (item) => item.status == "reverting" || item.status == "inprogress"
    // );
    // if (shouldShowSalesInProgressMessage) setRevertingAlert("reverting");
    // else setRevertingAlert(null);
    dispatch({
      type: `LIST_CREATE_SALE_RULES`,
      payload: res.bulk_price_responce,
    });
    dispatch({ type: `LIST_BULK_START_DATE`, payload: res.start_date });
    dispatch({ type: `LIST_BULK_END_DATE`, payload: res.end_date });
    // dispatch({
    //   type: `GETTING_FILTER_STATUS_FROM_RES`,
    //   payload: gettingFilterStatusFromResponse,
    // });
    // /*    const shouldShowSalesInProgressMessage = gettingFilterStatusFromResponse?.some((item)=>item.status == "reverting" || item.status == "inprogress")
    //     console.log(shouldShowSalesInProgressMessage, "shouldShowSalesInProgressMessage")
    //     if(shouldShowSalesInProgressMessage) setRevertingAlert("reverting");
    //     else setRevertingAlert("");
    //     */
  }

  const getSaleLogs = (caller = "initial") => {
    console.log("sale log");
    authenticatedFetch({path:`/api/getSaleLogs/${shop}?shop=${shop1}`,method: "get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getSaleLogs");
        }
      })
      .then((res) => {
        if (res.bulk_price_responce == 0) {
          dispatch({
            type: `LIST_CREATE_SALE_RULES_EMPTY`,
            payload: [],
          });
          return;
        }

        const statusArr = res.bulk_price_responce.map((item) => {
          return item.status;
        });
        // console.log(caller, salesLogStatusArr.toString() != statusArr.toString());
        if (res.bulk_price_responce !== 0 && caller == "initial") {
          setSalesLogStates(res);
        } else if (
          caller == "iteration"
          // &&
          // salesLogStatusArr.toString() != statusArr.toString()
        ) {
          setSalesLogStates(res);
          salesLogStatusArr = statusArr;
          // console.log("Sales Log", res);
        } else if (caller == "force") {
          setSalesLogStates(res);
          salesLogStatusArr = statusArr;
        }
      })

      .catch((error) => {});
  };

  const dragSaleLogModalSave = async (priorityList) => {
    authenticatedFetch({path:`/api/storeWideSalePriorityChange?shop=${shop1}`,method:"POST", body:JSON.stringify({ priorityList, shop }),host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong storeWideSalePriorityChange");
        }
      })
      .then((res) => {
        console.log("Response -> Sales Log -> Modal Save -> ", res);
        setSalesLogStates(res.saleLogs);
      })
      .catch((error) => {});
  };

  /*const getToastStatus = async () => {
    // console.log("Request -> Toast Status");

    await fetch(`/api/checking_queue_status/${shop}`, {
      method: "get",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong");
        }
      })
      .then((res) => {
        if (salesToastStatus != res.status) {
          // console.log("Response -> Toast Status -> ", res.status);
          dispatch({ type: `TOAST_STATUS`, payload: res.status });
          salesToastStatus = res.status;
        } else {
          // console.log('same toast status');
        }
      })
      .catch((error) => {
        console.log("Error -> Toast Status -> ", error);
      });
  };*/

  const cancelSaleLogs = async (metaFieldId) => {
    setLodingBulkTrue();
    authenticatedFetch({path:`/api/cancelSaleLogs/${metaFieldId}?shop=${shop1}`,method:"get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong cancelSaleLogs");
        }
      })
      .then((res) => {
        getSaleLogs();
        setTimeout(() => {
          setLodingBulkFalse();
        }, 5000);
      })
      .catch((error) => {});
  };

  const getSalePriceCount = (caller = "initial") => {
    authenticatedFetch({path:`/api/getSalePriceCount/${shop}?shop=${shop1}`,method:"get",host,jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getSalePriceCount");
        }
      })
      .then(({ storeMeteaFieldID }) => {
        if (caller == "initial") {
          dispatch({
            type: `UPDATED_PRODCUTS_REVERTING`,
            payload: storeMeteaFieldID,
          });
        } else if (
          caller == "iteration" &&
          storeMeteaFieldIDVal != storeMeteaFieldID
        ) {
          dispatch({
            type: `UPDATED_PRODCUTS_REVERTING`,
            payload: storeMeteaFieldID,
          });
          storeMeteaFieldIDVal = storeMeteaFieldID;
          setTimeout(() => {
            getSaleLogs("force");
          }, 5000);
        } else {
        }
      })
      .catch((error) => {});
  };

  const deleteSaleLog = (_id) => {
    setLodingBulkTrue();

    authenticatedFetch({path:`/api/deleteSaleLogs/${_id}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong deleteSaleLogs");
        }
      })
      .then((res) => {
        // dispatch({ type: `DELETE_SALE_LOG`, payload: discount_id });
        getSaleLogs();
        setTimeout(() => {
          setLodingBulkFalse();
        }, 4000);
      })
      .catch((error) => {});
  };

  const handleSalesLogToggle = (_id, index) => {
    setLodingBulkTrue();

    authenticatedFetch({path:`/api/toggleSaleLogs/${_id}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong toggleSaleLogs");
        }
      })
      .then((res) => {
        getSaleLogs();
        setTimeout(() => {
          setLodingBulkFalse();
        }, 4000);
      })
      .catch((error) => {});
  };

  const editCreateSaleRequest = (id, rulesObj) => {
    // fetch(`/api/editCreateSaleRequest/${id}`, {
    //   method: "put",
    //   headers,
    //   body: JSON.stringify(rulesObj),
    // })
    //   .then((res) => {
    //    if (res.ok) return res.json();
    //    else {
    //      throw new Error("Something went wrong");
    //    }
    //  })
    //   .then(({ msg, storewideSale, discount }) => { })
    //   .then(() => {
    //     setSavingFalse();
    //   })
    //   .then(() => {
    //     //redirect on successfull update
    //     // router.push("/AddedDiscounts");
    //   })
    //   .catch((error) => {
    //     console.log("update -> error", error);
    //   });
  };

  /////////////////////////////////////////////////////
  const getStoreDateTime = async () => {
    try {
      let data = await authenticatedFetch({path:`/api/getStoreCurrentDateTime/${shop}?shop=${shop1}`,method:"get", host, jwtToken,shop1});
      data = await data.json();

      const country_date_time = data.current_date_time.split(",");
      const country_date = country_date_time[0];
      const country_time_full = country_date_time[1];
      const country_time = country_time_full.slice(0, 5);
      // console.error({ country_date, country_time });
      return { country_date, country_time };
    } catch (err) {
      // console.error(err)
      return { error: err };
    }
  };
  // Country Code Time Date
  const getStoreCurrentDateTime = () => {
    authenticatedFetch({path: `/api/getStoreCurrentDateTime/${shop}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getStoreCurrentDateTime");
        }
      })

      .then(
        ({
          country_code,
          current_date_time,
          totalStoreProducts,
          timeZone,
          currency,
        }) => {
          // var moment = require("moment-timezone");
          let zone = timeZone.split(" ")[1];
          // zone = moment().tz(zone).zoneAbbr();

          // const country_date_time = current_date_time.split(",");
          // const country_date = country_date_time[0];
          // const country_time_full = country_date_time[1];
          // const country_time = country_time_full.slice(0, 5);
          // const stateChange = {
          //   countryCode: timeZoneCountryName,
          //   countryDate: country_date,
          //   countryTime: country_time,
          //   countryCurrency: currency,
          //   totalStoreProducts: totalStoreProducts,
          // };
          // var moment = require("moment-timezone");
          // const { DateTime } = require("luxon");

          // let zone = timeZone.split(" ")[1];
          // var newTime=DateTime.fromISO("2020-05-23T13:30:00+05:30", {zone: zone, locale: 'en-IN'})
          // .toFormat('ZZZZ');
          // console.log("New Time Zone Abbrivation:", newTime);
          // zone = moment().tz(zone).zoneAbbr();
          const country_date_time = current_date_time.split(",");
          console.log(current_date_time);
          const country_date = country_date_time[0];
          const country_time_full = country_date_time[1];
          const country_time = country_time_full.slice(0, 5);
          const stateChange = {
            countryCode: zone,
            countryDate: country_date,
            countryTime: country_time,
            countryDateTime: current_date_time,
            countryCurrency: currency,
            totalStoreProducts: totalStoreProducts,
          };
          // console.error("StateChange:", stateChange);
          dispatch({ type: "SET_SALE_BULK_INFO", payload: stateChange });
          /*   dispatch({ type: `COUNTRY_CODE`, payload: timeZoneCountryName });
          dispatch({ type: `COUNTRY_DATE`, payload: country_date });
          dispatch({ type: `COUNTRY_TIME`, payload: country_time });
          dispatch({ type: `COUNTRY_CURRENCY`, payload: currency });
          dispatch({
            type: `TOTAL_STORE_PRODUCTS`,
            payload: totalStoreProducts,
          });*/
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const clearFetchingStatus = () => {
    dispatch({ type: `PRODUCTS_FETCHING_STATUS`, payload: "" });
    dispatch({
      type: `PRODUCTS_FETCHING_STATUS_CURRENT_COUNT`,
      payload: 0,
    });
    dispatch({
      type: `PRODUCTS_FETCHING_STATUS_TOTAL_COUNT`,
      payload: 0,
    });
  };
  const navigateToUrl = (url) => {
    let element = document.createElement("a");
    if (url.startsWith("http://") || url.startsWith("https://")) {
      element.href = url;
    } else {
      element.href = "https://" + url;
    }
    element.target = "_top";
    element.click();
  };
  // Products Fetch On App Install
  const getProductsFetchingStatus = (retries = 5) => {
    // let lastFetchCompleted = false;
    authenticatedFetch({path: `/api/getProductsFetchingStatus/${shop}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        if (retries > 0) {
          // return getProductsFetchingStatus(retries - 1)
        }
        throw new Error("getProductsFetchingStatus",res?.status);
      })
      .then(({ status, random_count }) => {
        console.log("status, random_count", status, random_count);
        if (status.current_status == "inprogress") {
          // getProductsFetchingStatus();
          dispatch({ type: `PRODUCTS_FETCHING_STATUS`, payload: "inprogress" });
          dispatch({
            type: `PRODUCTS_FETCHING_STATUS_CURRENT_COUNT`,
            payload: random_count
          });
          dispatch({
            type: `PRODUCTS_FETCHING_STATUS_TOTAL_COUNT`,
            payload: status.total_product,
          });
        } else if (status.current_status == "completed") {
          // lastFetchCompleted = true;
          dispatch({ type: `PRODUCTS_FETCHING_STATUS`, payload: "completed" });
          dispatch({
            type: `PRODUCTS_FETCHING_STATUS_CURRENT_COUNT`,
            payload: status.current_product_count,
          });
          dispatch({
            type: `PRODUCTS_FETCHING_STATUS_TOTAL_COUNT`,
            payload: status.total_product,
          });
        } else if (status.current_status == "Failed") {
          // window.location.reload();
          // alert("Should Reload Now");
          // parent.location.reload()
          navigateToUrl(`${origin}/auth?shop=${shop}`);
        }
      })
      .catch((err) => {
        // if (!lastFetchCompleted) {
        console.log("Encountered Error While Fetching Product Status", err);
        // getProductsFetchingStatus();
        //   dispatch({ type: `PRODUCTS_FETCHING_STATUS`, payload: "inprogress" });
        //   dispatch({
        //     type: `PRODUCTS_FETCHING_STATUS_CURRENT_COUNT`,
        //     payload: status.current_product_count,
        //   });
        //   dispatch({
        //     type: `PRODUCTS_FETCHING_STATUS_TOTAL_COUNT`,
        //     payload: status.total_product,
        //   });
        // }
      });
  };

  //get Analytics Count
  const getAnalyticsCount = () => {
    authenticatedFetch({path:`/api/getDiscountsDetail?shop=${shop1}`, method:"get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong getDiscountsDetail");
        }
      })
      .then((res) => {
        dispatch({ type: `ANALYTICS_COUNT`, payload: res });
      })
      .catch((error) => {});
  };

  const CreateSaleUploadCsvFile = async (file,done = ()=>{}) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("shop", shop);

    await fetch("/api/cvsXlsxFileUploadStoreSale", {
      method: "post",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong cvsXlsxFileUploadStoreSale");
        }
      })
      .then(({ final_array, file_name, filter_sku,preparedPayload,allProductsPricesFound }) => {
        // const finArr = [];
        // let variantCount = 0;

        // for (let i = 0; i < final_array.length; i++) {
        //   for (let j = 0; j < final_array[i].variants.length; j++) {
        //     const variantId_3 = final_array[i].variants[
        //       j
        //     ].variant_id.toString();
        //     const variantId_2 = variantId_3.split("/");
        //     const variantId = variantId_2[4];

        //     const price = final_array[i].variants[j].variant_price.toString();

        //     let comparePrice = final_array[i].variants[j].variant_com_price;

        //     if (!comparePrice) {
        //       comparePrice = "null";
        //     }
        //     finArr.push(variantId.concat("$", price.concat("$", comparePrice)));
        //     variantCount++;
        //   }
        // }
        dispatch({ type: "BulkDiscount", payload: final_array });
        dispatch({ type: "BulkDiscountId", payload: preparedPayload });
        dispatch({ type: "VARIANT_COUNT", payload: preparedPayload.length });
        dispatch({ type: "UPLOADED_SKU", payload: filter_sku });
        /*
        status: "File uploaded successfully",
        file_name: fileName,
        status: "success",
        final_array: custom_array,
        preparedPayload: final_variants_array,
        allProductsPricesFound:allProductsPricesFound
        */
        // dispatch({ type: "PRICE_LOG_FILTER_STATUS", payload: res.status });
        // dispatch({ type: "PRICE_LOG_FILTER_STATUS_RESET", payload: "" });

        let fileUploadProductLength = preparedPayload.length;

        dispatch({
          type: `FILE_UPLOAD_DATA`,
          payload: { fileUploadProductLength, file_name },
        });
        // dispatch({ type: "BulkDiscountId", payload: final_array });
        // dispatch({ type: "VARIANT_COUNT", payload: fileUploadProductLength });
        done({final_array, file_name, filter_sku,preparedPayload,allProductsPricesFound});
      })
      .catch((error) => {});
  };
  const clearFileUploadData = async () => {
    dispatch({
      type: `FILE_UPLOAD_DATA`,
      payload: null,
    });
  };
  const clearImpactedVariantsList = () => {
    dispatch({ type: "SET_IMPACTED_VARIANTS", payload: {variants:[],impactedVariants:[]} });
  };
  const fetchImpactedVariants_SALES_LOG = async (data, logId, logType) => {
    clearImpactedVariantsList();
    // console.log("fetchImpactedVariants_SALES_LOG",data, logId, logType)
    authenticatedFetch({path:`/api/StoreSaleViewModel?shop=${shop1}`, method: "POST", body:JSON.stringify({
      data: {
        shop: shop,
        variant_ids: data,
        logId,
        logType,
      },
    }), host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong StoreSaleViewModel");
        }
      })
      .then(({ final_array,impactedVariants }) => {
        console.log(final_array,impactedVariants);
        dispatch({ type: "SET_IMPACTED_VARIANTS", payload: {variants:final_array,impactedVariants} });
      })
      .catch((error) => {});
  };

  const BulkUploadCsvFile = async (file) => {
    const formData = new FormData();
    console.log("file:",file);
    console.log("shop:",shop);
    formData.append("file", file);
    formData.append("shop", shop1);
    console.log("form data:",formData);
    await fetch("/api/cvsXlsxFileUploadBulkPriceChange", {
      method: "post",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong vsXlsxFileUploadBulkPriceChan");
        }
      })
      .then(({ status, file_name, final_array, variants_array }) => {
        dispatch({ type: "BulkDiscount", payload: final_array });
        dispatch({ type: "BulkDiscountId", payload: variants_array });
        dispatch({ type: "VARIANT_COUNT", payload: variants_array.length });

        let fileUploadProductLength = variants_array.length;

        dispatch({
          type: `FILE_UPLOAD_DATA`,
          payload: { fileUploadProductLength, file_name },
        });

        // checkBulkPriceChangeRequestImpectedVariants(variants_array);
      })
      .catch((error) => {});
  };

  const storeFrontDesign = async (data, cb = (error, data) => {}) => {
    authenticatedFetch({path: `/api/storeFrontDesign?shop=${shop1}`, method: "POST", body:JSON.stringify({ data, shop }), host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong storeFrontDesign");
        }
      })
      .then((res) => {
        cb(false, res);
      })
      .catch((error) => {
        cb(true);
      });
  };

  const getStoreFrontDesign = async () => {
    setLodingBulkTrue();
    authenticatedFetch({path:`/api/storeFrontDesign/${shop}?shop=${shop1}`, method:"get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong storeFrontDesign");
        }
      })
      .then(({ isAvailable, strikePrice }) => {
        if (isAvailable) {
          let clockType = strikePrice[0].design.clockType;
          let clockPosition = strikePrice[0].design.clockPosition;
          let backgroundColor =
            strikePrice[0].design.clockStyle.backgroundColor;
          let borderColor = strikePrice[0].design.clockStyle.borderColor;
          let color = strikePrice[0].design.clockStyle.color;
          let fontSize = strikePrice[0].design.clockStyle.fontSize;
          let margin = strikePrice[0].design.clockStyle.margin;
          let padding = strikePrice[0].design.clockStyle.padding;
          let textAlign = strikePrice[0].design.clockStyle.textAlign;
          let clockToggle = strikePrice[0].design.clockToggle;
          let fontFamily = strikePrice[0].design.clockStyle.fontFamily;
          let fontWeight = strikePrice[0].design.clockStyle.fontWeight;

          dispatch({
            type: `SALE_CLOCK_DATA`,
            payload: {
              clockType,
              clockPosition,
              backgroundColor,
              borderColor,
              color,
              fontSize,
              margin,
              padding,
              textAlign,
              clockToggle,
              fontFamily,
              fontWeight,
            },
          });
        } else {
          dispatch({
            type: `SALE_CLOCK_DATA`,
            payload: {
              clockType: "0",
              clockPosition: "top_on_product_div",
              backgroundColor: "#FFFFFF",
              borderColor: "#000000",
              color: "#000000",
              fontSize: 16,
              margin: 0,
              padding: 20,
              textAlign: "center",
              fontFamily: "sans-serif",
              fontWeight: "200",
            },
          });
        }
        setLodingBulkFalse();
      })
      .catch((error) => {
        setLodingBulkFalse();
      });
  };

  const getTopbarAlert = async () => {
    authenticatedFetch({path:`/api/checking_product_sync_status/${shop}?shop=${shop1}`,method: "get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong checking_product_sync_status");
        }
      })
      .then(({ status }) => {
        if (status != productSyncStatus) {
          dispatch({ type: "TOPBAR_ALERT_STATUS", payload: status });
          productSyncStatus = status;
        }
      })
      .catch((error) => {});
  };
  const syncSaleClock = async (callback) => {
    authenticatedFetch({path:`/api/syncSaleClock?shop=${shop1}`, method:"POST", body: JSON.stringify({ store: shop }), host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong syncSaleClock");
        }
      })
      .then((res) => {
        callback(res);
        console.log(res);
      })
      .catch((error) => {
        callback(false, true);
      });
  };
  const getProductsBasedOnSKUS = async (data, file_name, saleId, callback) => {
    authenticatedFetch({path:`/api/editFileStoreSale?shop=${shop1}`, method: "POST", body:JSON.stringify({ skus: data, shop: shop, saleId }),host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Something went wrong editFileStoreSale");
        }
      })
      .then(({ final_array, status, filter_sku, preparedPayload, priceFromCSV, allProductsPricesFound }) => {
        dispatch({ type: "BulkDiscount", payload: final_array });
        dispatch({ type: "BulkDiscountId", payload: preparedPayload });
        dispatch({ type: "UPLOADED_SKU", payload: filter_sku });
        const variantCount = () => {
          let count = 0;
          if (final_array && final_array.length > 0) {
            final_array.forEach((product) => {
              count += product.variants.length;
            });
          }
          return count;
        };
        let fileUploadProductLength = variantCount();
        dispatch({
          type: `FILE_UPLOAD_DATA`,
          payload: { fileUploadProductLength, file_name },
        });
        callback({
          titlePrice: final_array,
          filter_sku: filter_sku,
          fileUploadProductLength: fileUploadProductLength,
          file_name: file_name,
          priceFromCSV,
          allProductsPricesFound,
          preparedPayload
        });
      })
      .catch((error) => {
        console.log("Product Based on SKUS Returned Error", error);
      });
  };
  const setRevertingAlert = async (value) => {
    dispatch({ type: "TOPBAR_ALERT_STATUS", payload: value });
  };
  const clearUploadSKU = async () => {
    dispatch({ type: "UPLOADED_SKU", payload: [] });
  };
  const getEmbedStatus = async (callback = () => {}) => {
    authenticatedFetch({path:`/api/getStoreEmbeddedStatus/${shop}?shop=${shop1}`, method:"get", host, jwtToken,shop1})
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed To Get Embed App Status getStoreEmbeddedStatus");
      })
      .then((data) => {
        callback(false, data);
      })
      .catch((err) => {
        callback(true, err);
      });
  };

  return (
    <DiscountContext.Provider
      value={{
        //state objs
        saleLogToEdit: state.saleLogToEdit,
        variantsToReRunSaleOn: state.variantsToReRunSaleOn,
        variantsToReRunSaleOnWithProducts:
          state.variantsToReRunSaleOnWithProducts,
        message: state.message,
        discountRules: state.discountRules,
        singleDiscountRule: state.singleDiscountRule,
        loading: state.loading,
        saving: state.saving,
        loadingDiscounts: state.loadingDiscounts,
        toggleStatus: state.toggleStatus,
        shop: shop,

        bulkRules: state.bulkRules,
        loadingBulk: state.loadingBulk,
        titlePrice: state.titlePrice,
        impactedVariants: state.impactedVariants,
        BulkDiscountId: state.BulkDiscountId,
        discountRulesPriceChange: state.discountRulesPriceChange,
        ListBulkStartDate: state.ListBulkStartDate,
        ListBulkEndDate: state.ListBulkEndDate,
        countryCode: state.countryCode,
        countryDate: state.countryDate,
        countryTime: state.countryTime,
        hamburgerState: state.hamburgerState,
        countryCurrency: state.countryCurrency,
        totalUpdatedProducts: state.totalUpdatedProducts,
        totalUpdatedProductsReverting: state.totalUpdatedProductsReverting,
        variantCountArr: state.variantCountArr,
        totalStoreProductsCount: state.totalStoreProductsCount,
        productsFetchingStatus: state.productsFetchingStatus,
        productsFetchingStatusCurrentCount:
          state.productsFetchingStatusCurrentCount,
        productsFetchingStatusTotalCount:
          state.productsFetchingStatusTotalCount,
        analyticsCount: state.analyticsCount,
        priceLogFilterStatus: state.priceLogFilterStatus,
        gettingFilterStatusFromRes: state.gettingFilterStatusFromRes,
        fileUploadData: state.fileUploadData,
        discountRulesCreateSale: state.discountRulesCreateSale,
        ListCreateSaleStartDate: state.ListCreateSaleStartDate,
        ListCreateSaleEndDate: state.ListCreateSaleEndDate,
        salesClockData: state.salesClockData,
        mulipleBulkPriceChangeStatus: state.mulipleBulkPriceChangeStatus,
        toastStatus: state.toastStatus,
        topbarAlertStatus: state.topbarAlertStatus,
        queueStatus: state.queueStatus,
        uploadedSKU: state.uploadedSKU,
        filterLazyLoading: state.filterLazyLoading,
        logUnderObs: state.logUnderObs,
        subscriptionStatus: state.subscriptionStatus,
        error: state.error,
        totalAppliedRules: state.totalAppliedRules,
        //functions
        setVariantCount,
        clearImpactedVariantsList,
        fetchImpactedVariants_SALES_LOG,
        setSaleLogToEdit,
        createStorewideSale,
        listDiscountRules,
        getSingleDiscountRule,
        changeMessage,
        deleteDiscountRule,
        toggleIsActive,
        setLodingTrue,
        setLodingFalse,
        setSavingTrue,
        setSavingFalse,
        updateDiscountRule,
        clearSingleDiscountRule,
        setLodingDiscountsTrue,
        setLodingDiscountsFalse,
        getTopbarAlert,
        // getProductsList,
        createBulkOperation,
        saveBulkPriceChangeRequest,
        editBulkPriceChangeRequest,
        getBulkPriceChangeLog,
        cancelBulkPriceChangeLogs,
        getStoreCurrentDateTime,
        getUpdatedProducts,
        getBulkPriceChangeLogFirstTime,
        getAnalyticsCount,
        getProductsFetchingStatus,
        CreateSaleUploadCsvFile,
        BulkUploadCsvFile,
        createStorewideBulkOperation,
        saveCreateSaleRequest,
        saveBulkSaleRequest,
        editSaleRequest,
        getSaleLogsFirstTime,
        getSaleLogs,
        getSpecificSaleLog,
        dragSaleLogModalSave,
        cancelSaleLogs,
        getSalePriceCount,
        getStorewideFilteredProducts,
        deleteSaleLog,
        handleSalesLogToggle,
        editCreateSaleRequest,
        storeFrontDesign,
        getStoreFrontDesign,
        clearProductFilteringList,
        productImpactedModalList,
        checkBulkPriceChangeRequestImpectedVariants,
        setRevertingAlert,
        reRunSale,
        setVariantsToReRunSaleOn,
        checkQueueStatus,
        clearUploadSKU,
        clearFileUploadData,
        getProductsBasedOnSKUS,
        getStoreDateTime,
        checkSaleLogEndDates,
        syncSaleClock,
        bufferRequest,
        setSubscribtionStatus,
        setTotalAppliedRules,
        switchToPro,
        handleAPIError,
        switchToFree,
        getSubscriptionStatus,
        clearFetchingStatus,
        getEmbedStatus,
        setHamburgetState,
        // getToastStatus,
      }}
    >
      {props.children}
    </DiscountContext.Provider>
  );
};

export default DiscountState;
