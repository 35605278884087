import {
  SET_ETA,
  SET_TIMER,
  SET_FAKE_UPDATED,
  SET_ETA_TIME,
  SET_PRICE_CHANGE_INTERVAL,
  CLEAR_STATE,
  SET_TIMER_STATUS,
  SET_PRICE_CHANGE_VALUES,
  SET_CURRENT_ID,
} from "./actions";

// DONE represent Timer status
// FINISHED represent product update status
export const TimerStatus = {
  DONE_FINISHED: "DONE_FINISHED",
  DONE_NOT_FINISHED: "DONE_NOT_FINISHED",
  NOT_DONE_FINISHED: "NOT_DONE_FINISHED",
  NOT_DONE_NOT_FINISHED: "NOT_DONE_NOT_FINISHED",
};

export const initialState = {
  timerRunning: false,
  currentEtaTime: 0,
  currentFakeUpdated: 0,
  priceChangeInterval: [],
  timerStatus: TimerStatus.NOT_DONE_NOT_FINISHED,
  currentID: "",
};

function PriceChangeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ETA_TIME:
      return {
        ...state,
        currentEtaTime: action.payload,
      };

    case SET_PRICE_CHANGE_VALUES:
      let variants = 0;
      let status = state.timerStatus;
      if (
        state.currentFakeUpdated <
        action.payload.totalVariant - action.payload.addBy
      ) {
        variants = state.currentFakeUpdated + action.payload.addBy;
        console.log(
          "In progress",
          action.payload,
          state.currentFakeUpdated,
          variants
        );
      } else {
        // setTimerStatus(TimerStatus.DONE_NOT_FINISHED);
        // setCurrentFakeUpdated(payload.totalVariant);
        variants = action.payload.totalVariant;
        status = TimerStatus.DONE_NOT_FINISHED;
        console.log(
          "DONE NOT FINISHED",
          action.payload,
          state.currentFakeUpdated
        );
        console.log("Stopping the Interval for variants", new Date());
      }
      console.log("Current Fake Updated Variant Count", variants);
      if (state.currentEtaTime == 0)
        return {
          ...state,
          currentFakeUpdated: variants,
          timerStatus: status,
        };
      else {
        return {
          ...state,
          currentFakeUpdated: variants,
        };
      }

    case SET_TIMER:
      return {
        ...state,
        timerRunning: action.payload,
      };

    case SET_FAKE_UPDATED:
      return {
        ...state,
        currentFakeUpdated: action.payload,
      };

    case SET_PRICE_CHANGE_INTERVAL:
      return {
        ...state,
        priceChangeInterval: action.payload,
      };

    case SET_TIMER_STATUS:
      return {
        ...state,
        timerStatus: action.payload,
      };

    case SET_CURRENT_ID:
      return {
        ...state,
        currentID: action.payload,
      };

    case CLEAR_STATE:
      return {
        ...state,
        timerRunning: false,
        currentEtaTime: 0,
        currentFakeUpdated: 0,
        priceChangeInterval: [],
        timerStatus: TimerStatus.NOT_DONE_NOT_FINISHED,
        currentID: "",
      };
  }
}

export default PriceChangeReducer;
