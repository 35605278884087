import React, { useEffect } from "react";
import Head from "next/head";
import { AppProvider } from "@shopify/polaris";
import { Provider, Context, useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { Redirect } from "@shopify/app-bridge/actions";
import translations from "@shopify/polaris/locales/en.json";

// import ApolloClient from "apollo-boost";
// import { ApolloProvider } from "react-apollo";
// import "@shopify/polaris/build/esm/styles.css"; //for new version
import "@shopify/polaris/dist/styles.css"; // for old version
import DiscountState from "../context/discount/DiscountState";

import "/node_modules/bootstrap/scss/bootstrap.scss";
import "./styles/globals.scss";

import { PriceChangeProvider } from "../context/price_change/PriceChangeContext";
import { SalesProvider } from "../context/sales_timer/SalesContext";
import { SnackbarProvider } from "notistack";
import deepMerge from "@shopify/app-bridge/actions/merge";
import ErrorBoundary from "./ErrorBoundary";
import AppContext from "../context/appContext";


const yourCustomFetchWrapper = (uri, options) => {
  const aggregateOptions = deepMerge(options, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return fetch(uri, aggregateOptions);
};
function userLoggedInFetch(app) {
  const fetchFunction = authenticatedFetch(app);

  return async (uri, options) => {
    const response = await fetchFunction(uri, options);

    if (
      response.headers.get("X-Shopify-API-Request-Failure-Reauthorize") === "1"
    ) {
      const authUrlHeader = response.headers.get(
        "X-Shopify-API-Request-Failure-Reauthorize-Url"
      );

      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.APP, authUrlHeader || `/auth`);
      return null;
    }

    return response;
  };
}

function MyProvider(props) {
  const app = useAppBridge();

  // const client = new ApolloClient({
  //   fetch: userLoggedInFetch(app),
  //   fetchOptions: {
  //     credentials: "include",
  //   },
  // });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      credentials: "include",
      fetch: authenticatedFetch(app, yourCustomFetchWrapper),
    }),
  });
  // const client = new ApolloClient({
  //   uri: 'https://flyby-gateway.herokuapp.com/',
  //   cache: new InMemoryCache(),
  // });
  const { Component, ...rest } = props;

  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <Component {...rest} />
      </ErrorBoundary>
    </ApolloProvider>
  );
}
let host,shop1,jwtToken;
function MyApp(props) {

  console.log("Props", props.host1);
  const { Component, pageProps, host1, shopOrigin, jwtToken1 } = props;
  // useEffect(()=>{
  //   if(NOT_EMBEDDED_STORES.split(',').some((storeName) => shopOrigin == storeName) && jwtToken){
  //     localStorage.setItem('JWT', jwtToken);
  //   }
  // },[])
  // useEffect(()=>{
  //   window.JWT5=jwtToken1;
  //   console.log("useefect0", JWT5);
  // },[])
  
  // console.log("re-rendar");
  //console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  
  if(host1) host=host1;
  if(shopOrigin) shop1=shopOrigin;
  if((NOT_EMBEDDED_STORES.split(',').some((storeName) => shopOrigin == storeName)) && jwtToken1) jwtToken=jwtToken1;
  console.log("Jwt", jwtToken);
  // console.log(pageProps, host, shopOrigin);
  const isNotRedirectNeeded = NOT_EMBEDDED_STORES.split(',').some((storeName)=>shopOrigin==storeName);
  //  console.log(isNotRedirectNeeded);
  return (
    <AppContext.Provider value={{ host, jwtToken, shop1 }}>
    <AppProvider i18n={translations}>
      <Provider
        config={{
          apiKey: API_KEY,
          host: host,
          shopOrigin: shopOrigin,
          forceRedirect: NOT_EMBEDDED == "1" || (NOT_EMBEDDED == "0" && isNotRedirectNeeded) ? false : true,
        }}
      >
        <SnackbarProvider maxSnack={3}>
          <DiscountState>
            <PriceChangeProvider>
              <SalesProvider>
                <MyProvider Component={Component} {...pageProps} />
              </SalesProvider>
            </PriceChangeProvider>
          </DiscountState>
        </SnackbarProvider>
      </Provider>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.14.1/react-datepicker.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Lato&family=Roboto+Slab&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Roboto+Slab&display=swap"
        />
      </Head>
      {/* <script
          dangerouslySetInnerHTML={{
            __html: `window.fwSettings={
        'widget_id':73000001018
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`,
          }}
        ></script> */}
      <script
        type="text/javascript"
        src="//code.tidio.co/qxd4dlxibc3ah8hk4lec1kv6ori6kgzi.js"
        async
        // defer
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `document.addEventListener("tidioChat-open", onTidioChatApiOpen);
 
            function onTidioChatApiOpen() {
              document.removeEventListener("tidioChat-open", onTidioChatApiOpen);
              tidioChatApi.messageFromOperator('Hi, how can we help you today?');
              var message = "Hi, I have a question about Bevy Sales Rules";
              tidioChatApi.messageFromVisitor(message);
            }
            `,
        }}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `document.addEventListener('contextmenu', event => event.preventDefault());`,
        }}
      ></script>
    </AppProvider>
    </AppContext.Provider>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  return {
    host1: ctx.query.host,
    shopOrigin: ctx.query.shop,
    jwtToken1: ctx.query.jwtToken,
  };
};

export default MyApp;
