import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorMessage: "" };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    // console.log("Error Found", error);

    return { hasError: true, errorMessage: error?.message };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    // this.setState({ ...this.state, error: error });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-container">
          <div className="error-content">
            <div className="error-text">
              {this.state.errorMessage ? (
                <>
                  {this.state.errorMessage} <br></br>
                </>
              ) : (
                ""
              )}
              Bevy Sales Rules app encountered an unexpected error<br></br>
              Please make sure you have a stable network connection and then
              refresh the page to try again.
            </div>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
