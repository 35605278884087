import {
  SET_TIMER,
  SET_FAKE_UPDATED,
  SET_ETA_TIME,
  CLEAR_STATE,
  SET_TIMER_STATUS,
  SET_SALES_VALUES,
  SET_SALES_INTERVAL,
  PREVIOUS_PRODUCT_STATUS,
  SET_CURRENT_ID,
} from "./actions";

// DONE represent Timer status
// FINISHED represent product update status
export const TimerStatus = {
  DONE_FINISHED: "DONE_FINISHED",
  DONE_NOT_FINISHED: "DONE_NOT_FINISHED",
  NOT_DONE_FINISHED: "NOT_DONE_FINISHED",
  NOT_DONE_NOT_FINISHED: "NOT_DONE_NOT_FINISHED",
};

export const initialState = {
  timerRunning: false,
  currentEtaTime: 0,
  currentFakeUpdated: 0,
  salesInterval: [],
  timerStatus: TimerStatus.NOT_DONE_NOT_FINISHED,
  previousProductStatus: "",
  currentID: "",
};

function SalesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ETA_TIME:
      return {
        ...state,
        currentEtaTime: action.payload,
      };

    case SET_SALES_VALUES:
      let variants = 0;
      let status = state.timerStatus;
      console.log("acton ===> ", action);
      if (
        state.currentFakeUpdated <
        action.payload.totalVariant - action.payload.addBy
      ) {
        variants = state.currentFakeUpdated + action.payload.addBy;
      } else {
        variants = action.payload.totalVariant;
        status = TimerStatus.DONE_NOT_FINISHED;
      }
      if (state.currentEtaTime == 0)
        return {
          ...state,
          currentFakeUpdated: variants,
          timerStatus: status,
        };
      else {
        return {
          ...state,
          currentFakeUpdated: variants,
        };
      }

    case SET_TIMER:
      return {
        ...state,
        timerRunning: action.payload,
      };

    case SET_FAKE_UPDATED:
      return {
        ...state,
        currentFakeUpdated: action.payload,
      };

    case SET_SALES_INTERVAL:
      return {
        ...state,
        salesInterval: action.payload,
      };

    case SET_CURRENT_ID:
      return {
        ...state,
        currentID: action.payload,
      };

    case SET_TIMER_STATUS:
      return {
        ...state,
        timerStatus: action.payload,
      };

    case PREVIOUS_PRODUCT_STATUS:
      return {
        ...state,
        previousProductStatus: action.payload,
      };

    case CLEAR_STATE:
      console.log("CLEARING STATE");
      return {
        ...state,
        timerRunning: false,
        currentEtaTime: 0,
        currentFakeUpdated: 0,
        salesInterval: [],
        timerStatus: TimerStatus.NOT_DONE_NOT_FINISHED,
        previousProductStatus: "",
        currentID: "",
      };
  }
}

export default SalesReducer;
