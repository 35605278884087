import { createContext, useReducer } from "react";
import { calculateETA } from "../../components/componentUtils/eta";
import {
  CLEAR_STATE,
  PREVIOUS_PRODUCT_STATUS,
  SET_CURRENT_ID,
  SET_ETA_TIME,
  SET_FAKE_UPDATED,
  SET_SALES_INTERVAL,
  SET_SALES_VALUES,
  SET_TIMER,
  SET_TIMER_STATUS,
} from "./actions";
import SalesReducer, { initialState, TimerStatus } from "./SalesReducer";

const SalesContext = createContext();

export const SalesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SalesReducer, initialState);

  const setup = (
    addBy,
    totalVariant,
    countDownTime,
    id,
    alreadyUpdated,
    ETA
  ) => {
    setCurrentFakeUpdated(alreadyUpdated);
    setCurrentID(id);
    const interval = setInterval(() => {
      const diff = countDownTime - new Date().getTime();

      if (diff <= 0) {
        setETATime(0);
        console.log("Stopping the Countdown", new Date());
        setTimerStatus(TimerStatus.DONE_NOT_FINISHED);
      } else {
        setETATime(diff);
      }
    }, 1000);
    let timeForOneVariantUpdate = ETA / (totalVariant - alreadyUpdated);
    let variantsUpdatedPerSec = Math.ceil(1 / timeForOneVariantUpdate);
    console.log(
      "Time for One Variant To Update, ",
      timeForOneVariantUpdate,
      countDownTime - new Date().getTime(),
      countDownTime,
      totalVariant,
      alreadyUpdated,
      ETA
    );

    let intervalPerVariant = setInterval(() => {
      dispatch({
        type: SET_SALES_VALUES,
        payload: {
          totalVariant,
          addBy: variantsUpdatedPerSec,
          alreadyUpdated,
        },
      });
    }, 1000);
    dispatch({
      type: SET_SALES_INTERVAL,
      payload: [interval, intervalPerVariant],
    });
  };

  const clearState = () => {
    dispatch({
      type: CLEAR_STATE,
    });
  };

  const setETATime = (time) => {
    dispatch({
      type: SET_ETA_TIME,
      payload: time,
    });
  };

  const setTimerRunning = (isTrue) => {
    dispatch({
      type: SET_TIMER,
      payload: isTrue,
    });
  };

  const setCurrentFakeUpdated = (updated) => {
    dispatch({
      type: SET_FAKE_UPDATED,
      payload: updated,
    });
  };

  const setSalesInterval = (interval) => {
    dispatch({
      type: SET_SALES_INTERVAL,
      payload: interval,
    });
  };

  const setTimerStatus = (status) => {
    dispatch({
      type: SET_TIMER_STATUS,
      payload: status,
    });
  };

  const setCurrentID = (id) => {
    dispatch({
      type: SET_CURRENT_ID,
      payload: id,
    });
  };

  const clearSalesInterval = (interval) => {
    interval.forEach((interval_) => {
      clearInterval(interval_);
    });
    setSalesInterval([]);
    clearState();
  };

  return (
    <SalesContext.Provider
      value={{
        ...state,
        setup,
        setETATime,
        setTimerRunning,
        setCurrentFakeUpdated,
        setSalesInterval,
        clearSalesInterval,
        setCurrentID,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesContext;
