const reducer = (state, action) => {
  switch (action.type) {
    case `SET_SALE_LOG_TO_EDIT`:
      return {
        ...state,
        saleLogToEdit: { ...action.payload },
      };
    case `SET_API_ERROR`:
      return {
        ...state,
        error: { ...action.payload },
      };
    case `SET_HAMBURGER_STATE`:
      return {
        ...state,
        hamburgerState: action.payload,
      };
    case `SET_SUBSCRIPTION_STATUS`:
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case `SET_TOTAL_APPLIED_RULES`:
      return {
        ...state,
        totalAppliedRules: action.payload,
      };
    case `SET_VARIANTS_TO_RERUN_SALE_ON`:
      return {
        ...state,
        variantsToReRunSaleOn: [...action.payload],
      };
    case `SET_VARIANTS_TO_RERUN_SALE_ON_WITH_PRODUCTS`:
      return {
        ...state,
        variantsToReRunSaleOnWithProducts: [...action.payload],
      };
    case `CREATE_STOREWIDE_SALE`:
      return {
        ...state,
        discountRules: [action.payload, ...state.discountRules],
      };

    case `CREATE_PRICE_CHANGE`:
      return {
        ...state,
        discountRulesPriceChange: [
          action.payload,
          ...state.discountRulesPriceChange,
        ],
      };

    case `CREATE_SALE_LOG`:
      return {
        ...state,
        discountRulesCreateSale: [
          action.payload,
          ...state.discountRulesCreateSale,
        ],
      };

    case `LIST_DISCOUNT_RULES`:
      return { ...state, discountRules: action.payload };

    case `LIST_BULK_RULES`:
      return { ...state, discountRulesPriceChange: action.payload };

    case `LIST_CREATE_SALE_RULES`:
      return { ...state, discountRulesCreateSale: action.payload };

    case `LIST_CREATE_SALE_RULES_EMPTY`:
      return { ...state, discountRulesCreateSale: action.payload };

    case `LIST_BULK_START_DATE`:
      return { ...state, ListBulkStartDate: action.payload };

    // case `LIST_BULK_START_DATE`:
    //   return { ...state, ListCreateSaleStartDate: action.payload };

    case `LIST_BULK_END_DATE`:
      return { ...state, ListBulkEndDate: action.payload };

    // case `LIST_CREATE_SALE_END_DATE`:
    //   return { ...state, ListCreateSaleEndDate: action.payload };

    case `GET_SINGLE_DISCOUNT_RULE`:
      return {
        ...state,
        singleDiscountRule: action.payload,
      };

    case `CLEAR_SINGLE_DISCOUNT`:
      return {
        ...state,
        singleDiscountRule: null,
      };

    case `DELETE_DISCOUNT`:
      return {
        ...state,
        discountRules: state.discountRules.filter(
          ({ discount_id }) => discount_id !== action.payload
        ),
      };

    case `DELETE_SALE_LOG`:
      return {
        ...state,
        discountRulesCreateSale: state.discountRulesCreateSale.filter(
          ({ discount_id }) => discount_id !== action.payload
        ),
      };

    case `SET_LOADING_TRUE`:
      return {
        ...state,
        loading: true,
      };

    case `SET_LOADING_FALSE`:
      return {
        ...state,
        loading: false,
      };

    case `SET_LOADING_DISCOUNTS_TRUE`:
      return {
        ...state,
        loadingDiscounts: true,
      };

    case `SET_LOADING_DISCOUNTS_FALSE`:
      return {
        ...state,
        loadingDiscounts: false,
      };

    case `SET_LOADING_BULK_TRUE`:
      return {
        ...state,
        loadingBulk: true,
      };

    case `SET_LOADING_BULK_FALSE`:
      return {
        ...state,
        loadingBulk: false,
      };

    case `SET_SAVING_TRUE`:
      return {
        ...state,
        saving: true,
      };

    case `SET_SAVING_FALSE`:
      return {
        ...state,
        saving: false,
      };

    case `TOGGLE_STATUS`:
      return { ...state, toggleStatus: action.payload };

    case `TOGGLE_IS_ACTIVE`:
      let stateCopy = { ...state };
      let discountRulesCopy = [...stateCopy.discountRules];
      discountRulesCopy[action.payload].isActive = !discountRulesCopy[
        action.payload
      ].isActive;
      /*
        Keeping this just in case the upper code breaks
      */
      // state.discountRules[action.payload].isActive = !state.discountRules[
      //   action.payload
      // ].isActive;
      return { ...state, discountRules: discountRulesCopy };

    case `SALES_LOG_TOGGLE`:
      let stateCopyToggle = { ...state };
      let discountRulesCreateSaleCopy = [
        ...stateCopyToggle.discountRulesCreateSale,
      ];
      discountRulesCreateSaleCopy[
        action.payload
      ].isActive = !discountRulesCreateSaleCopy[action.payload].isActive;

      return { ...state, discountRulesCreateSale: discountRulesCreateSaleCopy };

    case `CHANGE_MESSAGE`:
      return { ...state, message: action.payload };

    case "ViewList":
      return { ...state, viewList: action.payload };

    case "BulkDiscount":
      return { ...state, titlePrice: action.payload };
    case "SET_IMPACTED_VARIANTS":
      return { ...state, impactedVariants: action.payload };

    case "BulkDiscountId":
      return { ...state, BulkDiscountId: action.payload };

    case "LOG_UNDER_OBSERVATION":
      return { ...state, logUnderObs: action.payload };
    case "SET_SALE_BULK_INFO":
      if (
        state.countryCode != action.payload.countryCode ||
        state.countryCurrency != action.payload.countryCurrency ||
        state.countryDate != action.payload.countryDate ||
        state.countryTime != action.payload.countryTime ||
        state.totalStoreProductsCount != action.payload.totalStoreProducts
      )
        return {
          ...state,
          countryCode: action.payload.countryCode,
          countryCurrency: action.payload.countryCurrency,
          countryDate: action.payload.countryDate,
          countryTime: action.payload.countryTime,
          totalStoreProductsCount: action.payload.totalStoreProducts,
        };
      else return state;
    case "COUNTRY_CODE":
      if (state.countryCode != action.payload)
        return { ...state, countryCode: action.payload };
      else return state;

    case "COUNTRY_CURRENCY":
      if (state.countryCurrency != action.payload)
        return { ...state, countryCurrency: action.payload };
      else return state;

    case "COUNTRY_DATE":
      if (state.countryDate != action.payload)
        return { ...state, countryDate: action.payload };
      else return state;

    case "COUNTRY_TIME":
      if (state.countryTime != action.payload)
        return { ...state, countryTime: action.payload };
      else return state;

    case "UPDATED_PRODCUTS":
      return { ...state, totalUpdatedProducts: action.payload };

    case "UPDATED_PRODCUTS_REVERTING":
      return { ...state, totalUpdatedProductsReverting: action.payload };

    case "ANALYTICS_COUNT":
      return { ...state, analyticsCount: action.payload };

    case "VARIANT_COUNT":
      return { ...state, variantCountArr: action.payload };

    case "TOTAL_STORE_PRODUCTS":
      if (state.totalStoreProductsCount != action.payload)
        return { ...state, totalStoreProductsCount: action.payload };
      else return state;

    case "PRODUCTS_FETCHING_STATUS":
      return { ...state, productsFetchingStatus: action.payload };

    case "PRODUCTS_FETCHING_STATUS_CURRENT_COUNT":
      return { ...state, productsFetchingStatusCurrentCount: action.payload };

    case "PRODUCTS_FETCHING_STATUS_TOTAL_COUNT":
      return { ...state, productsFetchingStatusTotalCount: action.payload };

    case "PRICE_LOG_FILTER_STATUS":
      return { ...state, priceLogFilterStatus: action.payload };

    case "PRICE_LOG_FILTER_STATUS_RESET":
      return { ...state, priceLogFilterStatus: action.payload };

    case "GETTING_FILTER_STATUS_FROM_RES":
      return { ...state, gettingFilterStatusFromRes: action.payload };

    case "FILE_UPLOAD_DATA":
      return { ...state, fileUploadData: action.payload };

    case "SALE_CLOCK_DATA":
      return { ...state, salesClockData: action.payload };

    case "MULIPLE_BULK_PRICE_CHANGE_STATUS":
      return { ...state, mulipleBulkPriceChangeStatus: action.payload };

    case "TOAST_STATUS":
      return { ...state, toastStatus: action.payload };

    case "TOPBAR_ALERT_STATUS":
      return { ...state, topbarAlertStatus: action.payload };
    case "SET_QUEUE_STATUS":
      if (JSON.stringify(state.queueStatus) != JSON.stringify(action.payload))
        return { ...state, queueStatus: action.payload };
      return state;
    case "UPLOADED_SKU":
      return { ...state, uploadedSKU: action.payload };
    case "SET_FILTER_LAZY_LOADING":
      return { ...state, filterLazyLoading: action.payload };
    default:
      return state;
  }
};

export default reducer;
